import React, {useEffect, useState} from 'react';
import redeemData from '../json/redeem.json';
import {Box, Button, Container, TextField, Typography} from '@mui/material';
import {styled} from '@mui/system';
import logo from "../img/Song Insider - Orange - A.png";
import {fetchSpotifyTrack, getToken} from '../spotifyService';
import CryptoJS from 'crypto-js';

const CountdownText = styled(Typography)({
    fontSize: '48px',
    fontWeight: 'bold',
    animation: 'pulse 1s infinite',
    '@keyframes pulse': {
        '0%': {
            transform: 'scale(1)',
            opacity: 1,
        },
        '50%': {
            transform: 'scale(1.1)',
            opacity: 0.7,
        },
        '100%': {
            transform: 'scale(1)',
            opacity: 1,
        },
    },
});

const Redeem = () => {
    const [inputPassword, setInputPassword] = useState('');
    const [associatedText, setAssociatedText] = useState('');
    const [who, setWho] = useState('');
    const [error, setError] = useState('');
    const [countdown, setCountdown] = useState(0);
    const [songUrl, setSongUrl] = useState<string | null>(null);
    const [songImage, setSongImage] = useState<string | null>(null);
    const [songTitle, setSongTitle] = useState('');
    const secretKey:any = process.env.REACT_APP_SECRET_KEY;

    /*
    const encrypt = (string:any) => {
        return CryptoJS.AES.encrypt(string, secretKey).toString();
    };

    const encrypted = encrypt('test');
    console.log(encrypted);
     */

    const decrypt = (encrypted:any) => {
        const bytes = CryptoJS.AES.decrypt(encrypted, secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    };
    const handlePasswordChange = (event: any) => {
        setInputPassword(event.target.value);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setError('');
        setAssociatedText('');
        setSongUrl(null);
        setSongImage(null);

        for (let item of redeemData) {
            if (inputPassword === decrypt(item.password)) {
                setAssociatedText(item.text);
                const decryptedWho = decrypt(item.who);
                setWho(decryptedWho);
                setCountdown(5);

                if (item.song) {
                    const token = await getToken();
                    const songResponse = await fetchSpotifyTrack(token, item.song);
                    setSongUrl(songResponse.external_urls.spotify);
                    setSongImage(songResponse.album.images[0].url);
                    setSongTitle(songResponse.name)
                }
                return;
            }
        }
        setError('Parola d\'ordine non valida');
    };

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown]);


    return (
        <Container maxWidth="sm">
            {!associatedText || countdown > 0 ? (
                <Box sx={{ mt: 4 }}>
                    <img src={logo} alt="Logo" style={{ height: '200px' }} />
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Un piccolo pensiero, una canzone...
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Spero ti faccia piacere
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            type="password"
                            value={inputPassword}
                            onChange={handlePasswordChange}
                            placeholder="Inserisci la parola d'ordine"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Invia
                        </Button>
                    </form>
                    {error && <Typography variant="body1" color="error" sx={{ mt: 2 }}>{error}</Typography>}
                    {associatedText && countdown > 0 && (
                        <>
                            <Typography variant="body1" sx={{ mt: 2 }}>
                                We We {who}!
                            </Typography>
                            <CountdownText>
                                {countdown}
                            </CountdownText>
                        </>
                    )}
                </Box>
            ) : (
                <Box sx={{ mt: 4 }}>
                    {songUrl && songImage && (
                        <Box sx={{mt: 2}}>
                            <a href={songUrl} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={songImage}
                                    alt="Cover"
                                    style={{
                                        width: '100%',
                                        cursor: 'pointer',
                                        borderRadius: '8px',
                                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    }}
                                    onMouseEnter={(e:any) => {
                                        e.target.style.transform = 'scale(1.05)';
                                        e.target.style.boxShadow = '0 4px 20px rgba(0, 0, 0, 0.2)';
                                    }}
                                    onMouseLeave={(e:any) => {
                                        e.target.style.transform = 'scale(1)';
                                        e.target.style.boxShadow = 'none';
                                    }}
                                />
                            </a>

                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#ff3801',
                                    textTransform: 'uppercase',
                                    display: 'flex',
                                    alignItems: 'center',
                                    letterSpacing: '2px',
                                    textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)',
                                    WebkitTextStroke: '1px black',
                                    textStroke: '1px black',
                                }}
                            >
                                {songTitle}
                            </Typography>
                            <Typography variant="body1">{decrypt(associatedText)}</Typography>
                        </Box>
                    )}
                </Box>
            )}
        </Container>
    );
};

export default Redeem;
