import rapPoesiaImage from './img/Rap_Poesia.jpg';
import bohemian_RhapsodyImage from "./img/Bohemian_Rhapsody.jpg";
import my_MammaImage from "./img/My_Mamma.jpg";
import jacopo_ratini_1Image from "./img/jacopo_ratini_1.jpg";
import cipImage from "./img/Cip.jpg";
import magmamemoriaImage from "./img/Magmamemoria.jpg";
import rimbaud_JimMorrisonImage from "./img/Rimbaud_JimMorrison.jpg"
import tarantelleImage from "./img/Tarantelle.jpg"
import mio_Fratello_è_Figlio_UnicoImage from "./img/Mio_Fratello_è_Figlio_Unico.jpg"
import la_Musica_di_strada_RoamanImage from "./img/La_Musica_di_strada_Roaman.png"
import musica_e_Rumore_BacciniImage from "./img/Musica_e_Rumore_Baccini.jpg"
import buon_Compleanno_ElvisImage from "./img/Buon_Compleanno_Elvis.jpg"
import the_Dark_side_of_the_moonImage from "./img/The_Dark_side_of_the_moon.jpg"
import giorni_FeliciImage from "./img/Giorni_Felici.jpg"
import nuovoSpazioTempoImage from "./img/NuovoSpazioTempo.jpg"
import vera_Muto1image from "./img/Vera_Muto_1.jpg"
import sanLevigo1image from "./img/SanLevigo_1.jpg"
import letteraQimage from "./img/LetteraQ.jpg"
import alaskaBabyImage from "./img/AlaskaBaby.jpg"
import èFinitaLaPaceImage from "./img/ÈFinitaLaPace.jpg"

const imageMap: { [key: string]: string } = {
    "Rap_Poesia": rapPoesiaImage,
    "Bohemian_Rhapsody": bohemian_RhapsodyImage,
    "My_Mamma": my_MammaImage,
    "jacopo_ratini_1": jacopo_ratini_1Image,
    "Cip": cipImage,
    "Magmamemoria": magmamemoriaImage,
    "Rimbaud_JimMorrison": rimbaud_JimMorrisonImage,
    "Tarantelle": tarantelleImage,
    "Mio_Fratello_è_Figlio_Unico": mio_Fratello_è_Figlio_UnicoImage,
    "La_Musica_di_strada_Roaman": la_Musica_di_strada_RoamanImage,
    "Musica_e_Rumore_Baccini": musica_e_Rumore_BacciniImage,
    "Buon_Compleanno_Elvis": buon_Compleanno_ElvisImage,
    "The_Dark_side_of_the_moon": the_Dark_side_of_the_moonImage,
    "Giorni_Felici": giorni_FeliciImage,
    "NuovoSpazioTempo": nuovoSpazioTempoImage,
    "Vera_Muto_1": vera_Muto1image,
    "SanLevigo_1": sanLevigo1image,
    "LetteraQ": letteraQimage,
    "AlaskaBaby": alaskaBabyImage,
    "ÈFinitaLaPace": èFinitaLaPaceImage
};

export default imageMap;