import React from 'react';
import { Typography, Container, Grid, Card, CardMedia } from '@mui/material';
import imageMap from "../imageMap";
import logo from '../img/Song Insider - Social Spotify.jpg';
import logo2 from '../img/Song Insider - Social-.jpg';
import { Link } from "react-router-dom";
import { useMediaQuery } from '@mui/material';

const Home = () => {
    const cardItems = [
        {
            image: imageMap['Rap_Poesia'],
            description: 'Leggi gli articoli e le curiosità',
            link: '/articles?type=article'
        },
        {
            image: imageMap['ÈFinitaLaPace'],
            description: 'Leggi le recensioni degli Album.',
            link: '/articles?type=review'
        },
        {
            image: imageMap['SanLevigo_1'],
            description: 'Leggi le interviste',
            link: '/articles?type=interview'
        }
    ];
    const isMobile = useMediaQuery('(max-width:700px)');

    const handleMouseEnter = (e:any) => {
        e.target.style.transform = 'scale(1.05)';
        e.target.style.boxShadow = '0 4px 20px rgba(0, 0, 0, 0.2)';
    };

    const handleMouseLeave = (e:any) => {
        e.target.style.transform = 'scale(1)';
        e.target.style.boxShadow = 'none';
    };

    return (
        <div className={'home'}>
            <Container>
                <Grid container spacing={4} style={{ marginTop: '2rem' }}>
                    {cardItems.map((item, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                            <Link to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        alt={item.description}
                                        height={isMobile ? '400' : '400'}
                                        image={item.image}
                                        title={item.description}
                                        style={{
                                            cursor: 'pointer',
                                            transition: 'transform 0.3s ease, box-shadow 0.3s ease'
                                        }}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <Typography variant="h6" align="center" style={{ padding: '1rem' }}>
                                        {item.description}
                                    </Typography>
                                </Card>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
                <Grid container spacing={4} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} sm={6}>
                        <Link to="/SongInsider" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    alt="Cos'è SongInsider"
                                    height={isMobile ? '200' : '300'}
                                    image={logo}
                                    title="Cos'è SongInsider"
                                    style={{
                                        cursor: 'pointer',
                                        transition: 'transform 0.3s ease, box-shadow 0.3s ease'
                                    }}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                />
                                <Typography variant="h6" align="center" style={{ padding: '1rem' }}>
                                    Cos'è SongInsider
                                </Typography>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Link to="/Interact" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    alt="Come interagire con SongInsider"
                                    height={isMobile ? '200' : '300'}
                                    image={logo2}
                                    title="Come interagire con SongInsider"
                                    style={{
                                        cursor: 'pointer',
                                        transition: 'transform 0.3s ease, box-shadow 0.3s ease'
                                    }}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                />
                                <Typography variant="h6" align="center" style={{ padding: '1rem' }}>
                                    Come interagire con SongInsider
                                </Typography>
                            </Card>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Home;
