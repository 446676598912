import axios from 'axios';

interface Artist {
    name: string;
}

interface Image {
    url: string;
}

interface Album {
    id: string;
    name: string;
    artists: Artist[];
    images: Image[];
    album_type: string;
}

interface Track {
    id: string;
    name: string;
    artists: Artist[];
    album: Album;
}

interface PlaylistTracksResponse {
    items: {
        track: Track;
    }[];
}

const clientId = 'ec4ff976ee204f54a5cbf18f9194d4b6';
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
const authUrl = 'https://accounts.spotify.com/api/token';
const apiUrl = 'https://api.spotify.com/v1';

export const getToken = async (): Promise<string> => {
    const response = await axios.post(
        authUrl,
        'grant_type=client_credentials',
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
            },
        }
    );
    return response.data.access_token;
};

export const getPlaylistTracks = async (
    token: string,
    playlistId: string,
): Promise<Track[]> => {
    const response = await axios.get<PlaylistTracksResponse>(`${apiUrl}/playlists/${playlistId}/tracks`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    return response.data.items
        .map(item => item.track)
        .filter(track => track !== null && track.album.album_type === 'single');
};

export const getPlaylistAlbums = async (
    token: string,
    playlistId: string,
): Promise<Track[]> => {
    const response = await axios.get<PlaylistTracksResponse>(`${apiUrl}/playlists/${playlistId}/tracks`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    return response.data.items
        .map(item => item.track)
        .filter(track => track !== null && track.album.album_type !== 'single');
};

export const fetchSpotifyTrack = async (token: string, trackId: string) => {
    const response = await fetch(`https://api.spotify.com/v1/tracks/${trackId}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    const data = await response.json();
    return data;
};
