import React, { useEffect, useState } from 'react';
import { getToken, getPlaylistTracks, getPlaylistAlbums } from '../spotifyService';
import { Grid, Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, Container, CircularProgress, Box, TextField } from '@mui/material';

interface Artist {
    name: string;
}

interface Image {
    url: string;
}

interface Album {
    id: string;
    name: string;
    artists: Artist[];
    images: Image[];
    album_type: string;
}

interface Track {
    id: string;
    name: string;
    artists: Artist[];
    album: Album;
}

const Releases: React.FC = () => {
    const [singles, setSingles] = useState<Track[]>([]);
    const [album, setAlbum] = useState<Track[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchQuery, setSearchQuery] = useState<string>('');

    useEffect(() => {
        const fetchPlaylistTracks = async () => {
            try {
                const token = await getToken();
                const playlistId = '4onhYuy8jH6skdIQPVvFAM';

                const [playlistSingles, playlistNonSingles] = await Promise.all([
                    getPlaylistTracks(token, playlistId),
                    getPlaylistAlbums(token, playlistId)
                ]);

                setSingles(playlistSingles);
                setAlbum(playlistNonSingles);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch playlist tracks');
                setLoading(false);
            }
        };

        fetchPlaylistTracks().then();
    }, []);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const filterTracksByArtist = (tracks: Track[]) => {
        return tracks.filter((track) =>
            track.artists.some((artist) =>
                artist.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    };

    const sortTracksByArtistName = (tracks: Track[]) => {
        return tracks.sort((a, b) => {
            const artistA = a.artists[0].name.toLowerCase();
            const artistB = b.artists[0].name.toLowerCase();
            return artistA.localeCompare(artistB);
        });
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    const filteredSingles = sortTracksByArtistName(filterTracksByArtist(singles));
    const filteredAlbums = sortTracksByArtistName(filterTracksByArtist(album));

    return (
        <Container style={{ marginTop: "1em" }}>
            <TextField
                label="Cerca Artista"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ marginBottom: "1em" }}
            />
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" gutterBottom>
                        Ultimi Singoli
                    </Typography>
                    <List>
                        {filteredSingles.map((track: Track) => (
                            <a
                                key={track.id}
                                href={`https://open.spotify.com/track/${track.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                <ListItem
                                    sx={{
                                        marginBottom: '8px',
                                        borderRadius: '8px',
                                        '&:hover': {
                                            backgroundColor: '#f1f1f1',
                                        }
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar src={track.album.images[0].url} variant="square" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={track.name}
                                        secondary={track.artists.map((artist: Artist) => artist.name).join(', ')}
                                    />
                                </ListItem>
                            </a>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" gutterBottom>
                        Ultimi Album
                    </Typography>
                    <List>
                        {filteredAlbums.map((track: Track) => (
                            <a key={track.id}
                               href={`https://open.spotify.com/album/${track.album.id}`}
                               target="_blank"
                               rel="noopener noreferrer"
                               style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                <ListItem
                                    sx={{
                                        marginBottom: '8px',
                                        borderRadius: '8px',
                                        '&:hover': {
                                            backgroundColor: '#f1f1f1',
                                        }
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar src={track.album.images[0].url} variant="square" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={track.album.name}
                                        secondary={track.album.artists.map((artist: Artist) => artist.name).join(', ')}
                                    />
                                </ListItem>
                            </a>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Releases;
