import React from 'react';
import imageMap from '../imageMap';
import articlesData from '../json/articles.json';
import { Typography, Grid, Box, Button, Link as MuiLink, useMediaQuery } from '@mui/material';
import { useParams, Link, useLocation } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Params() {
    return new URLSearchParams(useLocation().search);
}

function Post() {
    let { id } = useParams();
    const id_article = String(id);
    let params = Params();
    const type = params.get("type");
    const article: any = articlesData.find((item) => item['_id'] === id_article);
    const isMobile = useMediaQuery('(max-width:700px)');

    const getBackTitle = () => {
        if (type === 'article') {
            return 'agli Articoli';
        } else if (type === 'review') {
            return 'alle Recensioni';
        } else if (type === 'interview') {
            return 'alle Interviste';
        }
        return '';
    };

    const isQuestion = (text: any) => {
        return text.trim().endsWith('*');
    };

    const isContentEmpty = article.content.trim() === "";

    const extractYouTubeID = (url: string) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : null;
    };

    const youtubeID = article.youtube ? extractYouTubeID(article.youtube) : null;

    return (
        <Box className="article" sx={{ padding: 4 }}>
            <Button
                component={Link}
                to={"/articles?type=" + type}
                startIcon={<ArrowBackIcon />}
                sx={{ mb: 2 }}
            >
                Torna {getBackTitle()}
            </Button>
            <Typography variant="h4" component="h1" gutterBottom>
                {article.title}
            </Typography>
            <Grid container spacing={4}>
                {!isContentEmpty && (
                    <Grid item xs={12} md={8}>
                        <Typography variant="body1" component="div" gutterBottom sx={{ fontSize: '18px', lineHeight: '1.5' }}>
                            {article.content.split('\n').map((paragraph: any, index: any) => {
                                if (isQuestion(paragraph)) {
                                    return (
                                        <p key={index} style={{ fontWeight: 'bold' }}>
                                            {paragraph.slice(0, -1)}
                                        </p>
                                    );
                                } else {
                                    return (
                                        <p key={index}>
                                            {paragraph}
                                        </p>
                                    );
                                }
                            })}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            Data di pubblicazione: {new Date(article.publication_date).toLocaleDateString()}
                        </Typography>
                    </Grid>
                )}
                <Grid item container xs={12} md={isContentEmpty ? 12 : 4} spacing={2} alignItems="center">
                    {type === 'interview' && article.youtube && youtubeID && (
                        <Grid item xs={12} md={12}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <iframe
                                    width={isMobile ? '300' : '600'}
                                    height={isMobile ? '170' : '430'}
                                    src={`https://www.youtube.com/embed/${youtubeID}`}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12} md={12} display="flex" justifyContent="center" alignItems="center">
                        <img
                            src={imageMap[id_article]}
                            alt="Articolo"
                            style={{
                                maxHeight: isContentEmpty ? 'auto' : '40em',
                                maxWidth: isContentEmpty ? 'auto' : '100%',
                                width: isContentEmpty ? (isMobile ? '20em' : '30em') : '100%',
                                height: isContentEmpty ? 'auto' : 'auto',
                                objectFit: 'cover',
                            }}
                        />
                    </Grid>
                    {type === 'interview' && article.spotify && (
                        <Grid item xs={12} md={12}>
                            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                <MuiLink href={article.spotify} target="_blank" rel="noopener" variant="body1" display="flex"
                                         alignItems="center">
                                    <p style={{ marginRight: "1em" }}>Ascolta l'intervista su Spotify</p>
                                    <img width="120"
                                         alt="Spotify icon"
                                         src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Spotify_icon.svg/512px-Spotify_icon.svg.png?20220821125323" />
                                </MuiLink>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

export default Post;
